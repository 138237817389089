import { createAxiosByinterceptors } from "./api";

const request = createAxiosByinterceptors({
    baseURL: process.env.VUE_APP_BASE_API,
});

//lodaing配置
export const appList = (params) =>
  request.get("/app", { params, loading: true }); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

// 文件下载
export const exportGoods = (data) =>
  request.post("/export", data, {
    responseType: "blob",
  });

export default request;
